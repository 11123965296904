import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { BANK_ACCOUNT_ACTIVE_CHANGE } from '@fingo/lib/graphql';
import { useIsMobile } from '@fingo/lib/hooks';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Adjust from '@mui/icons-material/Adjust';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 6,
    padding: '0.3rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 18px rgba(0, 0, 0, 0, 0.05)',
    width: '100%',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflowWrap: 'break-word',
  },
  mobileFont: {
    fontSize: '3.5vw',
  },
}));

const AccountCardMini = (props) => {
  const {
    account,
    selected,
    onSelect,
    withSelect,
    editHandler,
  } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [bankAccountActiveChange] = useMutation(
    BANK_ACCOUNT_ACTIVE_CHANGE,
    { variables: { bankId: account.id } },
  );
  const bankNumberString = account.accountNumber.toString();
  const isSelected = account?.active && selected;

  return (
    <Card
      className={clsx(
        classes.card,
        isSelected ? classes.selected : null,
      )}
      onClick={isMobile ? () => onSelect(account.id) : null}
    >
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" width="100%">
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
        >
          {withSelect && (
            !isMobile ? (
              <IconButton
                variant="contained"
                size="small"
                color="primary"
                onClick={() => onSelect(account.id)}
                disabled={!account.active}
              >
                {isSelected ? <Adjust /> : <RadioButtonUnchecked />}
              </IconButton>
            ) : (
              <></>
            )
          )}
          <Stack
            direction="column"
            spacing={0.2}
          >
            <Typography variant="body2" className={isMobile ? classes.mobileFont : null}>
              {account.bankName}
            </Typography>
            <Typography variant="body2" className={isMobile ? classes.mobileFont : null}>
              {`${account.accountType}  **** ${bankNumberString.substr(bankNumberString.length - 4, 4)}`}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="column"
        >
          <IconButton onClick={editHandler}>
            <Edit />
          </IconButton>
          <IconButton onClick={bankAccountActiveChange}>
            <Delete />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};

AccountCardMini.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  withSelect: PropTypes.bool,
  editHandler: PropTypes.func,
};

AccountCardMini.defaultProps = {
  selected: false,
  onSelect: () => {},
  withSelect: true,
  editHandler: () => {},
};

export default AccountCardMini;
