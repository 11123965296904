import React from 'react';
import PropTypes from 'prop-types';
import getCdnUrl from '@fingo/lib/helpers/get-cdn-url';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import OpenInNew from '@mui/icons-material/OpenInNew';

const KOBROS_GUIDE = getCdnUrl('/documents/kobros_guide.pdf');

const KobrosDownloadGuideButton = ({ text }) => (
  <Button
    variant="outlined"
    size="small"
    sx={{
      borderRadius: '20px',
      height: 30,
      fontWeight: 'normal',
    }}
    id="collection-guide-button"
    onClick={() => window.open(KOBROS_GUIDE, '_blank')}
  >
    <Typography
      color="text.primary"
      variant="body1"
      component="span"
      marginRight={1}
    >
      {text}
    </Typography>
    <OpenInNew fontSize="5" color="text.primary" />
  </Button>
);

KobrosDownloadGuideButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default KobrosDownloadGuideButton;
