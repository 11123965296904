import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useIsMobile, useBooleanState } from '@fingo/lib/hooks';
import EmbedYouTubeDialog from '@fingo/lib/components/embedYouTubeDialog.js/EmbedYoutubeDialog';
import { getHelpVideoLink } from '@fingo/lib/constants';

const KobrosTutorial = (props) => {
  const isMobile = useIsMobile();
  const [openVideo, toggleOpenVideo] = useBooleanState();

  return (
    <>
      <Button
        variant="contained"
        onClick={() => toggleOpenVideo()}
        sx={{ display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          paddingY: 0,
          height: 35,
          justifyContent: { xs: 'center', md: 'initial' },
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          boxShadow: 'none',
          ...props,
        }}
        id="kobros-tutorial-button"
      >
        <Typography
          variant="caption"
          sx={{ textDecoration: 'underline', color: 'secondary.main', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {!isMobile && <PlayCircleIcon sx={{ marginRight: 0.5 }} />}
          Ver tutorial
        </Typography>
      </Button>
      <EmbedYouTubeDialog
        videoId={getHelpVideoLink('kobros-main-tutorial')}
        openVideo={openVideo}
        toggleOpenVideo={toggleOpenVideo}
      />
    </>
  );
};

export default KobrosTutorial;
