import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { CollectionDemoForm } from '../../features/collection/components/collection-register';

const KobrosLandingAskForDemoButton = ({ text }) => {
  const [
    openCollectionDemoConfirmationDialog,
    setOpenCollectionDemoConfirmationDialog,
  ] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{ borderRadius: '20px', height: 30, fontWeight: 'normal' }}
        id="request-more-info-button"
        onClick={() => {
          setOpenCollectionDemoConfirmationDialog(true);
        }}
      >
        <Typography
          variant="body2"
          component="span"
          marginRight={1}
          color="text.primary"
        >
          {text}
        </Typography>
      </Button>
      <FingoDialog
        open={openCollectionDemoConfirmationDialog}
        onClose={() => setOpenCollectionDemoConfirmationDialog(false)}
        handleClose={() => setOpenCollectionDemoConfirmationDialog(false)}
        title="Agenda una demo con nosotros"
        paperProps={{
          sx: {
            borderRadius: 0,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: {
              xs: '90vw',
              sm: '80vw',
              md: '60vw',
            },
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: '70vw',
              sm: '60vw',
              md: '50vw',
              lg: '30vw',
              xl: '30vw',
            },
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CollectionDemoForm />
        </Box>
      </FingoDialog>
    </>
  );
};

KobrosLandingAskForDemoButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default KobrosLandingAskForDemoButton;
