import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Button from '@mui/material/Button';
import AceptaLoginDialog from '../dialogs/AceptaLoginDialog';

const AceptaLoginButton = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialOpenAceptaLoginDialog = params.get('acepta') === 'true';
  const [openAceptaLoginDialog, setOpenAceptaLoginDialog] = useState(initialOpenAceptaLoginDialog);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        id="login-acepta"
        sx={{ borderRadius: 30, backgroundColor: '#21344A' }}
        fullWidth
        onClick={() => setOpenAceptaLoginDialog(true)}
      >
        Ingresar con Acepta
      </Button>
      {openAceptaLoginDialog && (
        <AceptaLoginDialog
          open={openAceptaLoginDialog}
          onClose={() => setOpenAceptaLoginDialog(false)}
        />
      )}
    </>
  );
};

export default React.memo(AceptaLoginButton);
