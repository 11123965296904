import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import { useGetUser, useIsMobile } from '@fingo/lib/hooks';
import { AccountCardMini, NewAccount, EditAccount } from '.';

const useStyles = makeStyles({
  menuItem: {
    overflow: 'unset',
    '&&:hover': {
      backgroundColor: 'inherit',
    },
  },
  button: {
    margin: '1rem',
  },
  buttonCredentials: {
    marginTop: '1rem',
  },
});

const AccountsListWithAdd = (props) => {
  const {
    setSelectedAccountInParent,
    xs,
    setCreatingNewAccount,
    withSelect,
    setDialogAlert,
  } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [accountToEdit, setAccountToEdit] = useState(null);
  const [activeAccounts, setActiveAccounts] = useState(null);
  const handleSelectAccount = (account) => {
    setSelectedAccountInParent(account);
    setSelectedAccount(account);
  };
  const user = useGetUser((data) => {
    const userAccounts = data?.getUser?.selectedCompany?.bankAccounts?.filter((a) => a?.active);
    setActiveAccounts(userAccounts);
    if (userAccounts.length === 0) {
      setDialogAlert({
        message: 'Aun no tienes ninguna cuenta. Debes agregar una cuenta bancaria.',
        severity: 'error',
        show: 'true',
      });
    }
  });

  if (accountToEdit) {
    return (
      <EditAccount
        cancel={() => setAccountToEdit(null)}
        account={accountToEdit}
        accounts={activeAccounts}
        setDialogAlert={setDialogAlert}
        onAccountEdit={(editedAccount) => {
          setActiveAccounts((prevState) => prevState.map((obj) => (
            obj.id === editedAccount?.id ? editedAccount : obj
          )));
          handleSelectAccount(editedAccount);
          setAccountToEdit(null);
        }}
      />
    );
  }

  if (showForm) {
    return (
      <NewAccount
        cancel={() => { setShowForm(false); setCreatingNewAccount(false); }}
        accounts={activeAccounts}
        onAccountCreate={(newAccount) => {
          handleSelectAccount(newAccount);
          setShowForm(false);
          setCreatingNewAccount(false);
        }}
        setDialogAlert={setDialogAlert}
      />
    );
  }

  if (!user) return <LinearProgress />;

  return (
    <>
      <Stack
        spacing={2}
        sx={{ py: 2, alignItems: 'center' }}
      >
        {activeAccounts?.length > 0
          && activeAccounts?.map((account) => (
            <AccountCardMini
              key={account.id}
              account={account}
              selected={selectedAccount?.id === account.id}
              onSelect={() => handleSelectAccount(account)}
              withSelect={withSelect}
              editHandler={() => setAccountToEdit(account)}
            />
          ))}
        <Button
          className={xs !== 12 ? classes.button : classes.buttonCredentials}
          variant={xs === 12 ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          onClick={() => { setShowForm(true); setCreatingNewAccount(true); }}
          fullWidth={xs === 12}
          sx={
            isMobile
              ? { width: '100%' }
              : { width: '50%' }
          }
        >
          {activeAccounts?.length > 0 ? 'Agregar otra cuenta' : 'Agregar una cuenta'}
        </Button>
      </Stack>
    </>
  );
};

AccountsListWithAdd.propTypes = {
  xs: PropTypes.number,
  setSelectedAccountInParent: PropTypes.func,
  setCreatingNewAccount: PropTypes.func,
  withSelect: PropTypes.bool,
  setDialogAlert: PropTypes.func.isRequired,
};

AccountsListWithAdd.defaultProps = {
  xs: 6,
  setSelectedAccountInParent: () => { },
  setCreatingNewAccount: () => { },
  withSelect: true,
};

export default AccountsListWithAdd;
